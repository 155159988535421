import {
  Link as RemixLink,
  LinkProps as RemixLinkProps,
} from "@remix-run/react";

export interface MailtoLinkProps
  extends Omit<RemixLinkProps, "to" | "target" | "children"> {
  /** The email address to send the email to */
  to: string;
  subject?: string;
  body?: string;
  /** @default show the `to` address as the link text */
  children?: React.ReactNode;
}

/**
 * A link that opens the user's email client
 *
 * This is just a plain Remix link (<a>) which means it doesn't handle any styling
 */
export const MailtoLink = ({
  to: toAddress,
  subject,
  body,
  children,
  ...linkProps
}: MailtoLinkProps) => {
  const params = new URLSearchParams();
  if (subject) {
    params.set("subject", subject);
  }
  if (body) {
    params.set("body", body);
  }

  return (
    <RemixLink
      {...linkProps}
      // Email clients handle `%20` more consistently than `+` for URL decoding
      to={`mailto:${toAddress}?${params.toString().replace(/\+/g, "%20")}`}
      target="_blank"
    >
      {children || toAddress}
    </RemixLink>
  );
};
